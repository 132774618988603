import React from "react";


class Range extends React.Component {

    render() {

        return <div>
            <input type="range" min="0" max="22" value={this.props.value} className="slider" onChange={(e) => this.props.onChange(e.target.value)}/>
            <div className="time-line">
                {this.props.items.map( (item, index) => {
                    return <div key={index} className="time-element">
                        <div className="time-element-top"></div>
                        <div className="time-element-text">{item}</div>
                    </div>;
                })}
            </div>
        </div>;
    }
}

export default Range;