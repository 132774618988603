import React from "react";


class TextButton extends React.Component {

    render() {

        return <div onClick={this.props.onClick} className="awake-button">
            <div style={this.props.active ? {background: '#3A767B', color: '#E7F3F4'} : undefined} className="awake-icon">
                {this.props.value}
            </div>
            <div className="awake-text">{this.props.value}</div>
        </div>;
    }
}

export default TextButton;