import React from "react";
import DiaryHistoryBlock from "./DiaryHistoryBlock";
import DateControl from "../common/DateControl";
import RecordsSwitch from "../common/RecordsSwitch";
import connection from "../scripts/connection";


class DiaryHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            history: [
             /* {
                id: 0,
                event: "Запись 1",
                thought: "string",
                place: "string",
                emotions: "string",
                symptoms: "string",
                behavior: "string",
                createDate: new Date("2024-08-31T15:06:20.908Z")
              },
              {
                id: 0,
                event: "Запись 2",
                thought: "string",
                place: "string",
                emotions: "string",
                symptoms: "string",
                behavior: "string",
                createDate: new Date("2024-08-31T16:06:20.908Z")
              },
              {
                id: 0,
                event: "Запись 3",
                thought: "string",
                place: "string",
                emotions: "string",
                symptoms: "string",
                behavior: "string",
                createDate: new Date("2024-08-31T17:06:20.908Z")
              },
              {
                id: 0,
                event: "Запись 4",
                thought: "string",
                place: "string",
                emotions: "string",
                symptoms: "string",
                behavior: "string",
                createDate: new Date("2024-08-30T15:06:20.908Z")
              },
              {
                id: 0,
                event: "Запись 5",
                thought: "string",
                place: "string",
                emotions: "string",
                symptoms: "string",
                behavior: "string",
                createDate: new Date("2024-07-26T15:06:20.908Z")
              },
              {
                id: 0,
                event: "Запись 5",
                thought: "string",
                place: "string",
                emotions: "string",
                symptoms: "string",
                behavior: "string",
                createDate: new Date("2024-05-26T15:06:20.908Z")
              }*/
            ],
            months: [
              //  new Date("2024-07-01T00:00:00"),
              //  new Date("2024-05-01T00:00:00"),
            ],
            groups: [

            ],
            currentGroup: [

            ],
            currentRecord: null,
            currentRecordIndex: 0,


            calendarDate: null
        }
    }


    async componentDidMount() {

        // get last record
        // get last record month
        // get all records for the month

        // group by date

        //
        let clientId = localStorage.getItem('clientId');



        await connection.AuthorizeGet('/clients/' + clientId + '/diary',
            (success) => {
                console.log(success)

                if (success.history.length === 0)
                    return;
                this.setState({history: success.history}, this.setupHistory)
            },
            (error) => {
                console.log(error)
            },
            (fatalError) => {
                console.log(fatalError)
            }
        )


        console.log(this.state)
    }


    setupHistory = () => {

        let data = {};

        this.state.history.forEach((item) => {

            item.createDate = new Date(item.createDate);
            let dt = new Date(item.createDate.getFullYear(), item.createDate.getMonth(), item.createDate.getDate());
            if (data[dt])
            {
                data[dt].push(item);   
            }
            else
            {
                data[dt] = [ item ];
            }

        });

        let latestRecord = this.state.history[0];
        let latestDate = new Date(latestRecord.createDate.getFullYear(), latestRecord.createDate.getMonth(), latestRecord.createDate.getDate());


        let calendarDate = new Date(latestRecord.createDate.getFullYear(), latestRecord.createDate.getMonth(), 1);

        let months = this.state.months;
        //months.push(calendarDate);

        // find minimum date
        // find maximum date
        // iterate & add
        let max = Math.max.apply(Math, this.state.history.map(x => x.createDate));
        let min = Math.min.apply(Math, this.state.history.map(x => x.createDate));
        let minDate = new Date(min);
        let maxDate = new Date(max);
        let currentDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
        while (currentDate <= maxDate)
        {
            months.push(currentDate);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        }


        this.setState({currentGroup: data[latestDate], groups: data, currentRecord: latestRecord, currentRecordIndex: 0, calendarDate: calendarDate, months: months});
    }

    showNext = () => {
        if (this.state.currentRecordIndex + 1 === this.state.currentGroup.length)
            return;

        this.setState({currentRecord: this.state.currentGroup[this.state.currentRecordIndex + 1], currentRecordIndex: this.state.currentRecordIndex + 1});
    }

    showPrev = () => {
        if (this.state.currentRecordIndex === 0)
            return;

        this.setState({currentRecord: this.state.currentGroup[this.state.currentRecordIndex - 1], currentRecordIndex: this.state.currentRecordIndex - 1});
    }


    getCurrentCalendarDates = () => {

        let result = [];

        let endDate = new Date(this.state.calendarDate.getFullYear(), this.state.calendarDate.getMonth() + 1, 1);

        for (const [key, value] of Object.entries(this.state.groups)) {
            let dt = new Date(key);    

            if (this.state.calendarDate <= dt && dt < endDate)
                result.push(dt);
        }


        return result;
    }


    selectDate = (date) => {
        let dt = new Date(this.state.calendarDate.getFullYear(), this.state.calendarDate.getMonth(), date);

        this.setState({currentGroup: this.state.groups[dt], currentRecord: this.state.groups[dt][0], currentRecordIndex: 0});
    }
    

    prevMonth = () => {
        let prevMonth = new Date(this.state.calendarDate.getFullYear(), this.state.calendarDate.getMonth() - 1, 1);

        if (this.state.months.find(x => x.getTime() === prevMonth.getTime()))
        {
            this.setState({calendarDate: prevMonth});

        }   
    }

    nextMonth = () => {
        let nextMonth = new Date(this.state.calendarDate.getFullYear(), this.state.calendarDate.getMonth() + 1, 1);
       
        if (this.state.months.find(x => x.getTime() === nextMonth.getTime()))
        {
            this.setState({calendarDate: nextMonth});

            /*
            // cache
            for (const [key, value] of Object.entries(this.state.groups)) {
    
                let dt = new Date(key);
                if (dt.getFullYear() === nextMonth.getFullYear() && dt.getMonth() === nextMonth.getMonth())
                {
                    this.setState({currentGroup: this.state.groups[dt], currentRecord: this.state.groups[dt][0], currentRecordIndex: 0, calendarDate: nextMonth});
                }
            }
            return;*/
        }   
    }

    render () {
        
        if (!this.state.currentRecord)
            return <div style={{textAlign: 'center'}}>Нет записей</div>;


        return (
        <>
            <DateControl 
                prevMonth={this.prevMonth} 
                nextMonth={this.nextMonth}
                onDateSelect={(date) => this.selectDate(date)} 
                calendarDate={this.state.calendarDate} 
                dates={this.getCurrentCalendarDates()} 
                recordDate={this.state.currentRecord.createDate}
            />

            <RecordsSwitch prev={this.showPrev} next={this.showNext} current={this.state.currentRecordIndex + 1} total={this.state.currentGroup.length} />

            <DiaryHistoryBlock item={this.state.currentRecord}/>
        </>
        );
    }
}

export default DiaryHistory;