import React from "react";
import WellnessTaskCard from "./WellnessTaskCard";
import PsyTaskCard from "./PsyTaskCard";
import TaskCard from "./TaskCard";
import BackButton from "../common/BackButton";


class TasktrackerPage extends React.Component {


    render() {

        return <div className="container tasktracker-container">

            <div className='diary-header'>
                <BackButton />
                <h4>Таск-трекер</h4>
                <div className='question-block'>
                    <img src='/img/icons/question-black.svg'/>
                </div>
            </div>

            <div className="tasktracker-banner">
                <div className="tasktracker-banner-content">
                    <div className="tasktracker-banner-header">
                        Цикл: Основа тревожного расстройства
                    </div>
                    <div className="tasktracker-banner-description">
                        Цикл: самочувствие и мотивация
                    </div>
                </div>
                <img src='/img/course-1.png'/>
            </div>

            <div className="tasktracker-header">
                Сегодня
            </div>
            <div className="tastracker-content">
                

                <TaskCard title={'Урок "Дневник самоанализа'} content={'Как работать с негативными мыслями'} practice={'Practice text'}/>

                <PsyTaskCard photo={'/img/psy-example.png'} />

                <WellnessTaskCard />


            </div>

        </div>;
    }
}

export default TasktrackerPage;