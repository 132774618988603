import React from "react";
import { Navigate } from "react-router-dom";


class MenuButton extends React.Component {

    constructor(props) {
        super(props);


        this.state = {

        }
    }

    render() {

        return <div onClick={() => this.setState({transition: true})} className="menu-button">
            {this.state.transition ? <Navigate to={this.props.url} /> : undefined}
            <img width={30} height={30} className="menu-icon" src={"/img/icons/" + this.props.icon} />
            <div className="menu-text">{this.props.text}</div>
            <img className="menu-forward" src="/img/icons/arrow-forward.svg" />
        </div>
    }
}


export default MenuButton;