import React from "react";


class RecordsSwitch extends React.Component {


    render () {

        return (
            <div className="items-list">
                <div onClick={this.props.prev} style={this.props.current > 1 ? {backgroundColor: '#3A767B'} : undefined} className="items-list-control">
                    <img src="/img/icons/arrow-left-small.svg" />
                </div>
                <div id="items-count">
                    {this.props.current} / {this.props.total}
                </div>
                <div onClick={this.props.next} style={this.props.current < this.props.total ? {backgroundColor: '#3A767B'} : undefined} className="items-list-control">
                    <img src="/img/icons/arrow-right-small.svg" />
                </div>
            </div>
        );
    }
}

export default RecordsSwitch;