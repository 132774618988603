import React from "react";
import DiaryHistoryItem from "./DiaryHistoryItem";


class DiaryHistoryBlock extends React.Component {

    render () {

        return (
        <div className='diary-item-container'>


            <DiaryHistoryItem title={'Событие - A'} placeholder={'Событие'} content={this.props.item.event} />

            <DiaryHistoryItem 
                title={'Автоматические мысли - B'} 
                placeholder={'Мысль'} 
                blockStyle={{background: 'linear-gradient(95.14deg, #CEEBED 0%, #C7EBEE 100%)'}}
                inputStyle={{backgroundColor: '#E7F3F4'}}
                content={this.props.item.thought}
                />

            <DiaryHistoryItem title={'Эмоция - C'} placeholder={'Эмоция'} content={this.props.item.emotions}/>

            <DiaryHistoryItem title={'Поведенческая реакция - D'} placeholder={'Реакция'} content={this.props.item.symptoms}/>
        </div>);
    }
}

export default DiaryHistoryBlock;