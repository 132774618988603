import React from "react";


class TestResult extends React.Component {

    render() {


        return <div className="test-result">
            <div className="test-result-header">
                <div>{this.props.title}</div>
                <div style={{color: '#3A767B'}}>{this.props.rate}</div>
            </div>
            <div className="test-result-description">
                <div>Нет</div>
                <div>Тяжелая</div>
            </div>
            <div className="test-result-indicator">
                <div style={{marginLeft: this.props.targetOffset}} className="test-result-target">
                    <div>Цель</div>
                    <svg className="test-result-target-icon" width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 5L0.901924 0.5L6.09808 0.5L3.5 5Z" fill="#474747"/>
                    </svg>
                </div>
                <div style={{marginLeft: this.props.cursorOffset}} className="test-result-cursor"></div>
            </div>
        </div>;
    }
}

export default TestResult;