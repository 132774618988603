import React from "react";


class FaqBlock extends React.Component {


    render () {

        return <div className="diary-faq">
            <div className="diary-faq-header">
                <div>{this.props.title}</div>
                <img onClick={this.props.onClose} className="faq-close" src='/img/icons/xmark.svg' />
            </div>
            <div className="diary-faq-content">
                {this.props.content}
            </div>
        </div>;
    }
}

export default FaqBlock;