import React from "react";


class TargetAdd extends React.Component {


    render() {

        return (
        <div>
            <div className="target-header">Цель №{this.props.targetNum}</div>
            <div onClick={this.props.onAdd} className="target-add">
                Добавить
                <img src="/img/icons/plus.svg" />
            </div>
        </div>);
    }
}

export default TargetAdd;