import React from "react";


class Rate extends React.Component {

    render() {
        return <div>
            <div className="rate-line">
                <div onClick={() => this.props.onSelect(1)} style={this.props.value === 1 ? {background: '#FFF'} : undefined} className="rate-item">1</div>
                <div onClick={() => this.props.onSelect(2)} style={this.props.value === 2 ? {background: '#FFF'} : undefined} className="rate-item">2</div>
                <div onClick={() => this.props.onSelect(3)} style={this.props.value === 3 ? {background: '#FFF'} : undefined} className="rate-item">3</div>
                <div onClick={() => this.props.onSelect(4)} style={this.props.value === 4 ? {background: '#FFF'} : undefined} className="rate-item">4</div>
                <div onClick={() => this.props.onSelect(5)} style={this.props.value === 5 ? {background: '#FFF'} : undefined} className="rate-item">5</div>
                <div onClick={() => this.props.onSelect(6)} style={this.props.value === 6 ? {background: '#FFF'} : undefined} className="rate-item">6</div>
                <div onClick={() => this.props.onSelect(7)} style={this.props.value === 7 ? {background: '#FFF'} : undefined} className="rate-item">7</div>
                <div onClick={() => this.props.onSelect(8)} style={this.props.value === 8 ? {background: '#FFF'} : undefined} className="rate-item">8</div>
                <div onClick={() => this.props.onSelect(9)} style={this.props.value === 9 ? {background: '#FFF'} : undefined} className="rate-item">9</div>
                <div onClick={() => this.props.onSelect(10)} style={this.props.value === 10 ? {background: '#FFF'} : undefined} className="rate-item">10</div>
            </div>
            <div className="rate-description">
                <div>1 – крайне плохо</div>
                <div>10 – идеально</div>
            </div>
        </div>;
    }
}

export default Rate;