import React from "react";


class DateSelector extends React.Component {


    constructCalendar = () => {
        // first day of week in the month
        // month length

        let day = (this.props.calendarDate.getDay() + 6) % 7;
        let monthLength = new Date(this.props.calendarDate.getFullYear(), this.props.calendarDate.getMonth() + 1, 0).getDate();
        let activeDates = [];

        for (let i = 0; i < monthLength; i++)
            activeDates.push(false);

        for (let i = 0; i < this.props.dates.length; i++)
        {
            activeDates[this.props.dates[i].getDate() - 1] = true;
        }



        let days = [];

        for (let i = 0; i < day; i++)
        {
            days.push(<td key={days.length}></td>);
        }

        for (let i = 0; i < monthLength; i++)
        {
            days.push(<td onClick={() => this.props.onSelect(i + 1)} key={days.length} style={activeDates[i] ? {borderRadius: '30px', background: '#10BB87'} : undefined}>{i + 1}</td>);
        }

        let itemsLeft = (7 - days.length % 7) % 7;

        for (let i = 0; i < itemsLeft; i++)
        {
            days.push(<td key={days.length}></td>);   
        }

        

        let result = [];

        let currentWeek = []

        for (let i = 0; i < days.length; i++)
        {
            currentWeek.push(days[i]);
            if ((i + 1) % 7 === 0)
            {
                result.push(<tr key={i}>{currentWeek}</tr>);
                currentWeek = [];
            }
        }

        return result;
    }

    getMonthName = (date) => {
        const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

        return months[date.getMonth()];
    }

    render () {

        return (
        <div className="calendar">
            <div className="calendar-header">
                <div className="calendar-header-title">{this.getMonthName(this.props.calendarDate)}</div>
                <div className="calendar-controls">
                    <img onClick={this.props.prevMonth} src="/img/icons/nav-arrow-left.svg" alt="Предыдущий"/>
                    <img onClick={this.props.nextMonth} src="/img/icons/nav-arrow-right.svg" alt="Следующий"/>
                </div>
            </div>
            <table className="calendar-content">
                <thead>
                <tr>
                    <td>П</td>
                    <td>В</td>
                    <td>С</td>
                    <td>Ч</td>
                    <td>П</td>
                    <td>С</td>
                    <td>В</td>
                </tr>
                </thead>
                <tbody>
                    {this.constructCalendar()}
                </tbody>
            </table>
            <div className="calendar-description">
                <div style={{borderRadius: '30px', width: '20px', height: '20px', backgroundColor: '#10BB87'}}></div>
                - Даты заполнения
            </div>
        </div>
        );
    }
}

export default DateSelector;