import React from "react";
import FaqBlock from "./FaqBlock";


class DiaryItem extends React.Component {


    render () {

        return (
        <div className='diary-item' style={this.props.blockStyle}>
            <div className="block-header">
                <span>{this.props.title}</span>
                <img onClick={this.props.toggleFaq} width={22} height={22}  src='/img/icons/question-black.svg'/>
            </div>
            <div className="block-content">
                <div className="text-field" style={this.props.inputStyle}>
                    <textarea onChange={(e) => this.props.onChange(e.target.value)} value={this.props.value ?? ''} style={this.props.inputStyle} type="text" placeholder={this.props.placeholder} ></textarea>
                </div>
            </div>
            {this.props.showFaq ? 
            <FaqBlock onClose={this.props.toggleFaq} title={this.props.title} content="Опишите, определите, что именно происходит вокруг вас. Это может быть что-то конкретное, например, разговор с начальником на работе, или более абстрактное, например, ощущение тревоги или грусти " />
            :
            undefined
            }
        </div>);
    }
}

export default DiaryItem;