import React from "react";


class DatePicker extends React.Component {


    constructor(props) {
        super(props);

        let dt = new Date();

        this.state = {
            currentMonth: 1, // for previous
            currentDate: new Date(dt.getFullYear(), dt.getMonth(), 1)
        }

    }

    constructCalendar = () => {
        // first day of week in the month
        // month length

        let day = (this.state.currentDate.getDay() + 6) % 7;
        let monthLength = new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, 0).getDate();


        let days = [];

        for (let i = 0; i < day; i++)
        {
            days.push(<td key={days.length}></td>);
        }

        for (let i = 0; i < monthLength; i++)
        {
            days.push(<td onClick={() => this.props.onSelect(new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth(), i + 1))} key={days.length}>{i + 1}</td>);
        }

        let itemsLeft = (7 - days.length % 7) % 7;

        for (let i = 0; i < itemsLeft; i++)
        {
            days.push(<td key={days.length}></td>);   
        }

        

        let result = [];

        let currentWeek = []

        for (let i = 0; i < days.length; i++)
        {
            currentWeek.push(days[i]);
            if ((i + 1) % 7 === 0)
            {
                result.push(<tr key={i}>{currentWeek}</tr>);
                currentWeek = [];
            }
        }

        return result;
    }

    getMonthName = (date) => {
        const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

        return months[date.getMonth()];
    }

    prevMonth = () => {
        if (this.state.currentMonth !== 1)
            return;

        let currentDate = new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() - 1, 1);

        this.setState({currentDate: currentDate, currentMonth: 0})
    }

    nextMonth = () => {
        if (this.state.currentMonth !== 0)
            return;

        let currentDate = new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, 1)

        this.setState({currentDate: currentDate, currentMonth: 1})
    }

    render () {

        return (
        <div className="calendar" style={{marginTop: '1rem'}}>
            <div className="calendar-header">
                <div className="calendar-header-title">{this.getMonthName(this.state.currentDate)}</div>
                <div className="calendar-controls">
                    <img onClick={this.prevMonth} src="/img/icons/nav-arrow-left.svg" alt="Предыдущий"/>
                    <img onClick={this.nextMonth} src="/img/icons/nav-arrow-right.svg" alt="Следующий"/>
                </div>
            </div>
            <table className="calendar-content">
                <thead>
                <tr>
                    <td>П</td>
                    <td>В</td>
                    <td>С</td>
                    <td>Ч</td>
                    <td>П</td>
                    <td>С</td>
                    <td>В</td>
                </tr>
                </thead>
                <tbody>
                    {this.constructCalendar()}
                </tbody>
            </table>
        </div>
        );
    }
}

export default DatePicker;