import React from "react";
import TargetView from "./TargetView";
import TargetAdd from "./TargetAdd";
import TargetEdit from "./TargetEdit";


class Target extends React.Component {

    render() {

        return <>
        {this.props.status === 0 ?
            <TargetAdd targetNum={this.props.targetNum} onAdd={() => this.props.changeStatus(2)} />
        : 
            undefined    
        }

        {this.props.status === 1 ?
            <TargetView targetNum={this.props.targetNum} onEdit={() => this.props.changeStatus(2)} onDelete={this.props.onDelete} content={this.props.content} />
        : 
            undefined    
        }

        {this.props.status === 2 ?
            <TargetEdit targetNum={this.props.targetNum} onChange={this.props.onChange} onSave={this.props.onSave} content={this.props.content}/>
        : 
            undefined    
        }

        </>;
    }
}

export default Target;