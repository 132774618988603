import React from "react";
import Range from "./Range";


class TimeBlock extends React.Component {


    getTimeline = (startTime) => {

        let result = [];

        let base = this.parseTime(startTime);

        for (let i = 0; i < 12; i++)
        {
            let hours = parseInt(base / 60);

            result.push((hours < 10 ? '0' + hours : hours) + ':00');

            base += 60;
            base %= 24 * 60;
        }

        return result;
    }

    parseTime = (time) => {
        let items = time.split(':');

        let minutesInHours = items[0] * 60;

        return minutesInHours + parseInt(items[1]);
    }

    getTimePosition = (time, startTime) => {
        let t = this.parseTime(time);
        let start = this.parseTime(startTime);

        let result = t - start;

        if (result < 0)
            result += 24 * 60;

        return result / 30;
    }

    convertValToTime = (val) => {
        let time = this.parseTime(this.props.startTime);
        time += val * 30;

        time %= 24 * 60

        let hours = parseInt(time / 60);
        let minutes = time % 60;

        let result = (hours < 10 ? '0' + hours : hours) + ':' + (minutes === 0 ? '00' : minutes);

        return result;
    }

    render() {
        return<div className="wellness-block">
            <div className="wellness-block-header wellness-block-header-time">
                <div className="wellness-block-header-title">{this.props.title}</div>
                <div className="wellness-block-header-display">{this.props.selectedTime}</div>
            </div>
            <Range onChange={(item) => this.props.onSelect(this.convertValToTime(item))} items={this.getTimeline(this.props.startTime)} value={this.getTimePosition(this.props.selectedTime, this.props.startTime)}/>
        </div>;
    }
}

export default TimeBlock;