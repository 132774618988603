import React from "react";
import DateIndicator from "./DateIndicator";
import DateSelector from "./DateSelector";


class DateControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showCalendar: false
        }
    }

    render () {

        return (
        <div className="date-control">
            <DateIndicator onClick={() => this.setState({showCalendar: !this.state.showCalendar})} date={this.props.recordDate}/>
            
            {this.state.showCalendar ?
            <DateSelector prevMonth={this.props.prevMonth} nextMonth={this.props.nextMonth} onSelect={(date) => this.setState({showCalendar: false}, () => this.props.onDateSelect(date))} calendarDate={this.props.calendarDate} dates={this.props.dates} />
            :
            undefined
            }
        </div>
        );
    }
}

export default DateControl;