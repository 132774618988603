import React from 'react'
import DiaryItem from './DiaryItem';
import DiaryBlock from './DiaryBlock';
import DiaryHistoryBlock from './DiaryHistoryBlock';
import DiaryMenu from './DiaryMenu';
import DiaryHistory from './DiaryHistory';
import MessageModal from '../common/MessageModal';
import BackButton from '../common/BackButton';


class DiaryPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1            
        }
    }

    componentDidMount() {
        this.allowTextareasToDynamicallyResize();
    }

    allowTextareasToDynamicallyResize = () =>  {
        let textareas = document.getElementsByTagName('textarea');
        for (let i = 0; i < textareas.length; i++) {
          textareas[i].addEventListener('input', (e) => {
            e.target.style.height = "5px";
            e.target.style.height = (e.target.scrollHeight) + "px";
          });
        }
    }

    render () {

        return <div className="container">

        <div className='diary-header'>
            <BackButton />
            <h4>Дневник рефлексии</h4>
            <div className='question-block'>
                <img src='/img/icons/question-black.svg'/>
            </div>
        </div>

        {this.state.showPsy ? 
            <MessageModal text={'Психолог регулярно изучает ваши данные'} onClose={() => this.setState({showPsy: false})} />
        :
            undefined
        }


        <DiaryMenu page={this.state.page} onPageChange={(page) => this.setState({page: page})} onPsyClick={() => this.setState({showPsy: true})} />

        {this.state.page === 1 ?
            <DiaryBlock />
        :
            <DiaryHistory />
        }
    </div>;
    }
}

export default DiaryPage;