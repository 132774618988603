import React from "react";


class TargetView extends React.Component {


    render() {

        return (
        <div>
            <div className="target-header">Цель №{this.props.targetNum}</div>
            <div className="target-content">
                <div style={{wordWrap: 'break-word'}}>{this.props.content}</div>
                <div className="target-controls">
                    <div onClick={this.props.onEdit} className="target-controls-edit"><img src='/img/icons/edit.svg' /> Редактировать</div>
                    <img onClick={this.props.onDelete} src='/img/icons/trash.svg' />
                </div>
            </div>
        </div>);
    }
}

export default TargetView;