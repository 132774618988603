import React from "react";
import Target from "./Target";
import TargetAdd from "./TargetAdd";
import TargetEdit from "./TargetEdit";
import TargetView from "./TargetView";
import connection from "../../scripts/connection";


class TargetsModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            targets: [
                {
                    id: 0,
                    content: '',
                    status: 0
                }
            ]
        }
    }


    componentDidMount() {

        let clientId = localStorage.getItem('clientId');
        connection.AuthorizeGet('/clients/' + clientId + '/targets',
            (success) => {
                console.log(success)

                let targets = success.targets ?? [];

                targets.forEach(item => { 
                    item.status = 1;
                    item.id = item.targetId;
                    item.content = item.text;
                
                });

                targets.push({id: 0, content: '', status: 0});


                this.setState({targets: success.targets})
            },
            (error) => {
                console.log(error)
            },
            (fatalError) => {
                console.log('Fatal')
            }
        )
    }

    changeStatus = (id, status) => {
        let item = this.state.targets.find(x => x.id === id);
        //let targets = this.state.targets.filter(x => x.id !== id);
        item.status = status;

        

        this.setState({targets: this.state.targets})
    }

    updateTarget = (id, content) => {
        let item = this.state.targets.find(x => x.id === id);
        //let targets = this.state.targets.filter(x => x.id !== id);

        item.content = content;

        

        this.setState({targets: this.state.targets})
    }

    saveTarget = (id) => {
        let item = this.state.targets.find(x => x.id === id);

        if (!item.content)
            return;

        let clientId = localStorage.getItem('clientId');

        if (id === 0)
        {
            //item.id = this.state.targets.length + '1';
            //item.status = 1;

            // create request
            connection.AuthorizePost('/clients/' + clientId + '/targets',
                {
                    text: item.content
                },
                (success) => {
                    
                    item.id = success;
                    item.status = 1;
                    this.state.targets.push({id: 0, status: 0});
                    this.setState({targets: this.state.targets});
                },
                (error) => {
                    console.log(error);
                },
                (fatalError) => {
                    console.log(fatalError);
                }
            )
        }
        else
        {
            // update request
            //item.status = 1;

            connection.AuthorizePut('/clients/' + clientId + '/targets/' + item.id,
                {
                    text: item.content
                },
                (success) => {
                    
                    item.status = 1;
                    this.setState({targets: this.state.targets});
                },
                (error) => {
                    console.log(error);
                },
                (fatalError) => {
                    console.log(fatalError);
                }
            )

            //this.setState({targets: this.state.targets});
        }
    }

    deleteTarget = (id) => {
        let item = this.state.targets.find(x => x.id === id);

        // delete request
        let clientId = localStorage.getItem('clientId');

        connection.AuthorizeDelete('/clients/' + clientId + '/targets/' + item.id,
            (success) => {
                let targets = this.state.targets.filter(x => x.id !== id);
                this.setState({targets: targets});
            },
            (error) => {
                console.log(error);
            },
            (fatalError) => {
                console.log(fatalError);
            }
        )
    }

    render() {

        return <div className="targets-block">
            <div className="targets-block-header">
                Мои цели
                <img onClick={this.props.onClose} className="faq-close targets-close" src='/img/icons/xmark.svg' />
            </div>
            <div className="targets-container">
                {this.state.targets.map( (item, index) => {
                    return <Target
                            key={index}
                            targetNum={index + 1}
                            onDelete={() => this.deleteTarget(item.id)}
                            onSave={() => this.saveTarget(item.id)}
                            onChange={(val) => this.updateTarget(item.id, val)}
                            content={item.content}
                            changeStatus={(status) => this.changeStatus(item.id, status)}
                            status={item.status}
                    />
                })}
            </div>
        </div>;
    }
}

export default TargetsModal;