import React from "react";


class WellnessTaskCard extends React.Component {


    render() {

        return <div className="tasktracker-card">
            <div className="tasktracker-card-header">
                Сканер состояния
                <img className="tasktracker-card-icon" src="/img/icons/wellness-tasktracker.svg"/>
            </div>
            <div className="tasktracker-card-content">
                5 мин. заполнения
            </div>
            <div className="tasktracker-card-controls">
                <div className="tasktracker-card-button" style={{background: '#3A767B'}}>
                    Заполнить сканер состояния
                </div>
            </div>
        </div>;
    }
}


export default WellnessTaskCard;