import React from "react";


class WellnessMenu extends React.Component {


    render() {

        return <div className="inner-menu">
        {this.props.page === 1 ?
        <a className="inner-menu-item inner-menu-active">
            <img src="/img/icons/diary.svg" alt=""/>
            <span>Дневник</span>
        </a>
        :
        <a onClick={() => this.props.onPageChange(1)} className="inner-menu-item">
            <img src="/img/icons/diary-black.svg" alt=""/>
            <span>Дневник</span>
        </a>
        }

        <a onClick={() => this.props.onPsyClick()} className="inner-menu-item psy-item" style={{background: '#3A767B'}}>
            <div>
                <img className="psy-img" src="/img/psy-example.png" />
            </div>
            <div className="psy-watch">
                <img className="psy-watch-icon" width={18} height={18} src="/img/icons/eye.svg" />
                <span>Ваш психолог</span>
            </div>
        </a>
    </div>;
    }
}

export default WellnessMenu;