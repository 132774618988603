import React from "react";
import TestResult from "./TestResult";


class TestFinal extends React.Component {

    render() {


        return <div>
            <div className="test-final-header">
                Ваши показатели тревожности, депрессии и выгорания по результатам тестирования
            </div>

            <div className="test-result-container">
                <TestResult title={'Тревога: тяжелая'} rate={'15/20'} targetOffset={'50px'} cursorOffset={10 / 20 * 330}/>
                <TestResult title={'Депрессия: легкая'} rate={'7/27'} targetOffset={'50px'} cursorOffset={7 / 27 * 330}/>
                <TestResult title={'Выгорание: умеренная'} rate={'10/24'} targetOffset={'50px'} cursorOffset={10 / 24 * 330}/>
            </div>

        </div>;
    }
}

export default TestFinal;