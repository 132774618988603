import React from "react";
import TestResult from "./TestResult";
import BackButton from "../common/BackButton";


class TestPreview extends React.Component {


    render() {

        return <div className="container">
            <div className='diary-header'>
                <BackButton />
                <h4>Клиническое тестирование</h4>
                <div className='question-block'>
                    <img src='/img/icons/question-black.svg'/>
                </div>
            </div>
            

            <div className="test-preview-header">
                <div className="test-preview-header-content">
                    <div className="test-preview-header-title">Клиническое тестирование</div>
                    <div className="test-preview-header-description">Пройдите тестирование, чтобы установить выраженность Ваших симптомов за прошедшие 2 недели</div>
                </div>
                <img src="/img/test-preview-2.png" alt=""/>
            </div>
            
            <div className="test-preview-button">
                <div className="test-preview-button-text">Клинический тест</div>
                <ul>
                    <li>8 минут</li>
                </ul>
                <img style={{position: 'absolute', top: '50%', right: '15px', transform: 'translate(0, -50%)'}} src="/img/icons/test-preview-icon.svg" alt=""/>
            </div>

            <div className="test-preview-subtitle">Ваши показатели тревожности, депрессии и выгорания по результатам предыдущего тестирования</div>
            <div className="test-result-container">
                <TestResult title={'Тревога: тяжелая'} rate={'15/20'} targetOffset={'50px'} cursorOffset={10 / 20 * 330}/>
                <TestResult title={'Депрессия: легкая'} rate={'7/27'} targetOffset={'50px'} cursorOffset={7 / 27 * 330}/>
                <TestResult title={'Выгорание: умеренная'} rate={'10/24'} targetOffset={'50px'} cursorOffset={10 / 24 * 330}/>
            </div>
        </div>;
    }
}

export default TestPreview;