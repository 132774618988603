import React from "react";


class TargetEdit extends React.Component {


    componentDidMount() {
        this.allowTextareasToDynamicallyResize();
    }

    allowTextareasToDynamicallyResize = () =>  {
        let textareas = document.getElementsByTagName('textarea');
        for (let i = 0; i < textareas.length; i++) {
          textareas[i].addEventListener('input', (e) => {
            e.target.style.height = "5px";
            e.target.style.height = (e.target.scrollHeight) + "px";
          });
        }
    }


    render() {

        return (
            <div>
                <div className="target-header">Цель №{this.props.targetNum}</div>
                <div className="target-content">
                    <textarea onChange={(e) => this.props.onChange(e.target.value)} value={this.props.content ?? ''}  placeholder="Впишите свои цели"/>
                    <div onClick={this.props.onSave} className={"target-save" + (this.props.content ? " target-save-active" : "")}>
                        Сохранить
                        <img src="/img/icons/check.svg" />
                    </div>
                </div>
            </div>);
    }
}

export default TargetEdit;