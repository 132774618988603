import React from "react";
import DiaryItem from "./DiaryItem";
import connection from "../scripts/connection";
import { eventWrapper } from "@testing-library/user-event/dist/utils";
import MessageModal from "../common/MessageModal";


class DiaryBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    send = () => {

        if (!this.state.event || !this.state.thought || !this.state.emotions || !this.state.behavior)
        {
            this.setState({message: 'Заполните все поля'});
            return;
        }

        let clientId = localStorage.getItem('clientId');
        connection.AuthorizePost('/clients/' + clientId + '/diary', 
        {
            event: this.state.event,
            thought: this.state.thought,
            emotions: this.state.emotions,
            symptoms: this.state.behavior,
            behavior: "1"
        },
        (success) => {
            console.log(success)
            this.setState({message: 'Данные отправлены', event: null, thought: null, emotions: null, behavior: null})
        },
        async (error) => {
            console.log(error)
            console.log(await error.json())
            this.setState({message: 'Произошла ошибка, попробуйте позже'});
        },
        (fatalError) => {
            console.log(fatalError)
            this.setState({message: 'Произошла ошибка, попробуйте позже'});
        }
        );
    }

    render () {

        return (
            <div className='diary-item-container'>


        
        {this.state.message ?
            <MessageModal text={this.state.message} onClose={() => this.setState({message: null})}/>
        :
            undefined
        }

            <DiaryItem 
                title={'Событие - A'} 
                placeholder={'Событие'} 
                showFaq={this.state.faq === 1} 
                toggleFaq={() => this.setState({faq: this.state.faq === 1 ? 0 : 1})}
                
                onChange={(val) => this.setState({event: val})}
                value={this.state.event}
                />

            <DiaryItem 
                title={'Автоматические мысли - B'} 
                placeholder={'Мысль'} 
                blockStyle={{background: 'linear-gradient(95.14deg, #CEEBED 0%, #C7EBEE 100%)'}}
                inputStyle={{backgroundColor: '#E7F3F4'}}
                showFaq={this.state.faq === 2}
                toggleFaq={() => this.setState({faq: this.state.faq === 2 ? 0 : 2})}

                onChange={(val) => this.setState({thought: val}) }
                value={this.state.thought}
                />

            <DiaryItem 
                title={'Эмоция - C'} 
                placeholder={'Эмоция'} 
                showFaq={this.state.faq === 3} 
                toggleFaq={() => this.setState({faq: this.state.faq === 3 ? 0 : 3})}

                onChange={(val) => this.setState({emotions: val})}
                value={this.state.emotions}
                />

            <DiaryItem 
                title={'Поведенческая реакция - D'} 
                placeholder={'Реакция'} 
                showFaq={this.state.faq === 4} 
                toggleFaq={() => this.setState({faq: this.state.faq === 4 ? 0 : 4})}
                
                onChange={(val) => this.setState({behavior: val})}
                value={this.state.behavior}
                />


            <div className="block-content end-block">
                <button onClick={this.send} className="complete-button">Отправить</button>
            </div>
        </div>
        );
    }
}

export default DiaryBlock;