import React from "react";


class MessageModal extends React.Component {


    render () {

        return (
            <div className="modal-back">
                <div className="modal-window">
                    <div className="modal-text">
                        {this.props.text}
                    </div>
                    <hr style={{margin: 0}} className="modal-hr" />
                    <div onClick={this.props.onClose} className="modal-text">
                        {this.props.button ?? 'Понятно'}
                    </div>
                </div>
            </div>
        );
    }
}

export default MessageModal;