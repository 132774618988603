import React from "react";
import { Navigate } from "react-router-dom";


class BackButton extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            transition: false
        }
    }

    render() {

        return <>
            {this.state.transition ? <Navigate to="/" /> : undefined }
            <img style={{width: '39px', height: '24px'}} onClick={() => this.setState({transition: true})} src='/img/icons/arrow-back.svg'/>
        </>;
    }
}

export default BackButton;