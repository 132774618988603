import React from "react";


class ProgressBar extends React.Component {


    render() {

        return <div className="progress-indicator">
            <div className="progress-indicator-text">
                <div>Вовлеченность в терапию</div>
                <div>
                    <span className="progress-actual">5</span>
                    <span className="progress-total">/7</span>
                </div>
            </div>
            <div className="progress-button">
                Ваш прогресс
            </div>
        </div>;
    }
}

export default ProgressBar;