import React from "react";
import TestQuestion from "./TestQuestion";
import TestFinal from "./TestFinal";
import TestStart from "./TestStart";
import BackButton from "../common/BackButton";


class TestPage extends React.Component {


    render() {

        return <div className="container">
            <div className='diary-header'>
                <BackButton />
                <h4>Клиническое тестирование</h4>
                <div className='question-block'>
                    <img src='/img/icons/question-black.svg'/>
                </div>
            </div>

{/*
            <div className="test-progressbar">
                <div className="test-progressbar-text">Прогресс 33%</div>
                <div className="test-progress">
                    <div className="test-progress-behind"></div>
                    <div style={{width: '33%'}} className="test-progress-active"></div>
                </div>
            </div>

            <TestQuestion 
                title={'8. Вы двигались или говорили настолько медленно, что окружающие могли бы это заметить? Или наоборот, вы были настолько суетливы или взбудоражены, что передвигались гораздо больше обычного'}
                options={['Вариант 1', 'Вариант 2', 'Вариант 3', 'Вариант 4']}    
            />
    */}

            <TestStart />
        </div>;
    }
}

export default TestPage;