import React from "react";


class DateIndicator extends React.Component {

    parseDate = (date, $1, $2, $3, separator='') => {
        const dateObj = {
            date: String(date.getDate()).padStart(2, '0'),
            month: String(date.getMonth() + 1).padStart(2, '0'),
            year: date.getFullYear()
        };

        return dateObj[$1] + separator + dateObj[$2] + separator + dateObj[$3];
    }

    parseTime = (date) => {
        return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    }

    render () {

        return (
        <div className="save-controls">
            <div onClick={this.props.onClick} className="save-selector">
                <img src="/img/icons/arrow-left-small.svg" alt=""/>
                <span>{this.parseDate(this.props.date, 'date', 'month', 'year', '.')}</span>
                <img src="/img/icons/arrow-right-small.svg" alt=""/>
            </div>
            
            {(this.props.showTime ?? true) ?
            <div className="save-time">
                {this.parseTime(this.props.date)}
            </div>
            :
            undefined
            }
        </div>
        );
    }
}

export default DateIndicator;