import React from "react";
import TestQuestionAnswer from "./TestQuestionAnswer";


class TestQuestion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedAnswer: null
        }
    }

    render() {

        return <div>
            <div className="test-title">{this.props.title}</div>
            <div className="test-answers-container">
                {this.props.options.map((item, index) => {
                    return <TestQuestionAnswer key={index} onSelect={() => this.setState({selectedAnswer: index})} active={this.state.selectedAnswer === index} text={item}/>;    
                })}
            </div>

            {this.state.selectedAnswer !== null ?
                <div onClick={() => this.props.onSelect(this.state.selectedAnswer)}  className="test-button-continue">
                    Далее
                    <img src="/img/icons/arrow-forward.svg" alt=""/>
                </div>
            :
                undefined
            }
        </div>;
    }
}

export default TestQuestion;