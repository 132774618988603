import React from "react";


class PsyTaskCard extends React.Component {


    render() {

        return <div className="tasktracker-card">
            <div className="tasktracker-card-header">
                <span className="tasktracker-card-title">Домашнее задание от Вашего психолога</span>
                <img className="tasktracker-card-icon tasktracker-psy-icon" src={this.props.photo} />
            </div>
            <div className="tasktracker-card-content">
                Психолог подготовил для Вас персональное домашнее задание
            </div>
            <div className="tasktracker-card-controls">
                <div className="tasktracker-card-controls-main">
                    <div className="tasktracker-card-button" style={{background: '#3A767B'}}>Задание</div>
                    <div className="tasktracker-card-button" style={{background: '#E26833'}}>Отправить задание</div>
                </div>
            </div>
        </div>;
    }
}


export default PsyTaskCard;