import React from "react";


class TestQuestionAnswer extends React.Component {


    render() {

        return <div onClick={this.props.onSelect} className={"test-button" + (this.props.active ? " test-button-active" : "")}>
            {this.props.text}
        </div>;
    }
}

export default TestQuestionAnswer;