import React from "react";


class TestStart extends React.Component {


    render() {


        return <div style={{marginLeft: 'auto', marginRight: 'auto', width: '330px'}}>
            <img style={{borderRadius: '20px', marginTop: '15px'}} src="/img/test-preview.png" alt=""/>
            <div className="test-start-header">
                Пройдите тестирование для определения Ваших симптомов тревоги, депрессии и выгорания
            </div>
            <div className="test-start-description">
                Отвечать необходимо основываясь на своих ощущениях в течение последних 14 дней.
            </div>

            <div onClick={this.props.onStart} className="test-start-button">
                Начать тест
            </div>
            <div className="test-start-notice">
                8 минут на прохождение
            </div>
        </div>;
    }
}

export default TestStart;