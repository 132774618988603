import React from "react";


class TaskCard extends React.Component {


    constructor(props) {
        super (props);

        this.state = {
            showTask: false
        }
    }

    render() {

        return <div className="tasktracker-card">
            <div className="tasktracker-card-header">
                <span className="tasktracker-card-title">{this.props.title}</span>
                <img className="tasktracker-card-icon" src="/img/icons/study.svg"/>
            </div>
            <div className="tasktracker-card-content">
                {this.props.content}
            </div>
            <div className="tasktracker-card-controls">
                <div className="tasktracker-card-controls-main">
                    <div className="tasktracker-card-button" style={{background: '#3A767B'}}>Изучить</div>
                    <div className="tasktracker-card-button" style={{background: '#E26833'}}>Завершить урок</div>
                </div>
                <div onClick={() => this.setState({showTask: !this.state.showTask})} className="tasktracker-card-button transparent-button">
                    Практика
                </div>
            </div>
            { this.state.showTask ?
                <div className="tasktracker-card-content">
                    {this.props.practice}
                </div>
            :
                undefined
            }
        </div>;
    }
}


export default TaskCard;