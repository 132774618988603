import React from "react";


class DiaryHistoryItem extends React.Component {

    render () {

        return (
            <div className='diary-item' style={this.props.blockStyle}>
            <div className="block-header">
                <span>{this.props.title}</span>

            </div>
            <div className="block-content">
                <div className="text-field" style={this.props.inputStyle}>
                    {this.props.content}
                </div>
            </div>
        </div>
        );
    }
}

export default DiaryHistoryItem;