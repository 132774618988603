import React from "react";


class ColorizedIconButton extends React.Component {

    render () {

        return <div onClick={this.props.onClick} className="asleep-item">
                <div style={this.props.active ? {background: this.props.fill, color: '#E7F3F4', borderColor: this.props.color} : {borderColor: this.props.color}} className="awake-icon">
                    {!this.props.active ?
                    <img id="asleep-mid-img" src={this.props.img} />
                    :
                    <img id="asleep-mid-img" src={this.props.activeImg} />
                    }
                </div>
                <div style={{color: this.props.color}} className="awake-text">{this.props.title}</div>
            </div>;
    }
}


export default ColorizedIconButton;